const answer_question_data = [
  {
    id: 1,
    question: "How can DigiExpo help with digital marketing?",
    answer: <>We are the top digital marketing agency in Dubai, offering services meant to attract more traffic, improve conversion probability, and thus profit your business more. They employ a range of trends and concepts supported by the data to make outstanding changes to your brand.</> ,
    accordion_id: "headingOne",
    collapsed: "collapsed",
    data_bs_target: "#collapseOne",
    aria_controls: "collapseOne", 
    actice: "",
  },
  {
    id: 2,
    question: " What makes DigiExpo's website development unique?",
    answer: <>At DigiExpo, we have qualified Web Developers who will ensure that your business gets the website you envisioned for your brand: effective and easy-to-use websites. As we are the best Digital marketing agency UAE, Our essential concentration is on the aesthetic design of the websites, as well as usability and user-friendly designs.</> ,
    accordion_id: "headingTwo",
    collapsed: "",
    data_bs_target: "#collapseTwo",
    aria_expanded: true,
    aria_controls: "collapseTwo",
    show: true,
    actice: "",

  },
  {
    id: 3,
    question: "How does DigiExpo approach social media marketing?",
    answer: <>As the best digital marketing agency in UAE, we have a professional SMM who will enhance your brand’s exposure, interaction, and profitability. At DigiExpo, we devise an actualized market strategy to ensure your brand reaches the right people on the most active social sites.</> ,
    accordion_id: "headingThree",
    collapsed: "collapsed",
    data_bs_target: "#collapseThree",
    aria_controls: "collapseThree",
    actice: "",

  },
  {
    id: 4,
    question: "What are the benefits of DigiExpo's SEO services?",
    answer: <>As for SEO and content creation services, DigiExpo offers all the necessary solutions for your business that will help to improve your business web visibility and attract targeted traffic. We apply professional methods that will enhance the position of your website in terms of search engine results.</> ,
    accordion_id: "headingFour",
    collapsed: "collapsed",
    data_bs_target: "#collapseFour",
    aria_controls: "collapseFour", 
    actice: "",

  },
  {
    id: 5,
    question: "How does DigiExpo ensure business growth?",
    answer: <>We are your one-stop solution for generating your online presence from idea to sale. We will assist you in sustaining and attaining your organization's objectives by translating clicks into sales and offering you extensive services as well as valuable information.</> ,
    accordion_id: "headingFive",
    collapsed: "collapsed",
    data_bs_target: "#collapseFive",
    aria_controls: "collapseFive", 
    actice: "",

  },
  //Digital Marketing
  {
    id: 6,
    question: "What digital marketing services does DigiExpo offer?",
    answer: <>DigiExpo, the digital marketing agency in UAE offers digital marketing solutions that include Social Media Marketing (SMM), Search Engine Optimization (SEO), Performance Marketing, Email Marketing, App Store Optimization (ASO), Content writing and influencer Marketing, and Content creation. We strive to devise the most suitable and efficient plans for you to achieve the best results in all your online operations.</> ,
    accordion_id: "headingSix",
    collapsed: "collapsed",
    data_bs_target: "#collapseSix",
    aria_controls: "collapseSix", 
    actice: "",
  },
  {
    id: 7,
    question: "How can Social Media Marketing (SMM) help my business?",
    answer: <>Our SMM services ensure visitors' casual clicks while browsing through your business's Facebook, Instagram, or linked-in accounts are changed into loyal customers. At the reliable best digital marketing agency, we make the content and the approach as interactive as can be and that steady fan and customer base you need from your targeted audience.</> ,
    accordion_id: "headingSeven",
    collapsed: "",
    data_bs_target: "#collapseSeven",
    aria_expanded: true,
    aria_controls: "collapseSeven",
    show: true,
    actice: "",

  },
  {
    id: 8,
    question: "What benefits can I expect from your Search Engine Optimization (SEO) services?",
    answer: <>Most of the SEO services we offer aim to ensure that your site ranks high in search engine results and gets traffic. At our digital marketing agency we use effective and efficient techniques to enhance your Internet business's standing and increase your chances of attaining a higher rank in search engine listings and, subsequently, more bona fide Internet clientele.
</> ,
    accordion_id: "headingEight",
    collapsed: "collapsed",
    data_bs_target: "#collapseEight",
    aria_controls: "collapseEight",
    actice: "",

  },
  {
    id: 9,
    question: "How does Performance Marketing contribute to my ROI?",
    answer: <>Performance marketing is based on fine-tuning one or multiple types of advertisements to provide measurable conversion. Our best digital marketing agency specialists apply various scientific approaches to improve your advertising campaign, increasing your ROI. This means that you will be assured of an equivalent return on investment for every penny you spend on marketing.</> ,
    accordion_id: "headingNine",
    collapsed: "collapsed",
    data_bs_target: "#collapseNine",
    aria_controls: "collapseNine", 
    actice: "",

  },
  {
    id: 10,
    question: "What is included in your Content Creation service?",
    answer: <>Our Content Creation service entails writing and designing eye-catching online stories that your audience will relate to. We create appealing and convincing content to strengthen your online brand reputation. From blog posts to social media content, our team creates stories that captivate and inspire.</> ,
    accordion_id: "headingTen",
    collapsed: "collapsed",
    data_bs_target: "#collapseTen",
    aria_controls: "collapseTen", 
    actice: "",

  }, 
  {
    id: 11,
    question: "What makes DigiExpo the best choice for web development in Dubai?",
    answer: <>At DigiExpo, we remain the most suitable web development company in Dubai. We deeply understand your vision, value proposition, target market, and objectives as a business entity.<br/> We design and develop simple yet remarkable and highly effective websites that fit your business dreams perfectly and pave the way for the future. Our services include WordPress, Laravel, PHP, CMS development, website and application maintenance, and WhatsApp integration, thus offering holistic service delivery based on your requirements.</> ,
    accordion_id: "headingTenOne",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenOne",
    aria_controls: "collapseTenOne", 
    actice: "",

  }, 
  {
    id: 12,
    question: "Will my Website be Mobile-Friendly?",
    answer: <>Yes, business owners must have a mobile-friendly website these days! We make sure that your website appears fantastic on a range of gadgets. In mobile search results, Google gives mobile-friendly websites priority over those that are not.<br/>Credibility with your customers, clients, and industry influencers is increased via mobile-friendly websites. Anyone trying to access your website on a mobile device will have a suitable experience if it is mobile-friendly, and this will encourage visitors to view you as a reliable source of information, goods, and services.</> ,
    accordion_id: "headingTenTwo",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenTwo",
    aria_controls: "collapseTenTwo", 
    actice: "",

  }, 
  {
    id: 13,
    question: "What is included in your Content Creation service?",
    answer: <>Redesigning the current website occasionally is a good idea. As a top web development company in Dubai claims that a clever yet unique website makeover would improve user experience and assist clients with occasionally new perspectives.<br/>The process comprises goal-setting, competitor analysis, redesigning approaches, search engine functionality, digital marketing practices, etc. The team of designers at DigiExpo is highly skilled and knowledgeable, and they can revamp your website so that it performs two times better than it does now.</> ,
    accordion_id: "headingTenThree",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenThree",
    aria_controls: "collapseTenThree", 
    actice: "",

  }, 
  {
    id: 14,
    question: "Can DigiExpo help with updating and maintaining my existing website?",
    answer: <>Absolutely! DigiExpo provides complete website management services to ensure your website is fast, protected against hacks and has no bugs. It means that you can safely delegate all website-related activities, such as updating the security or creating backups, to us and optimize the website to improve its performance while you can concentrate on developing your business. The intended goal is to ensure that your website is always working effectively.</> ,
    accordion_id: "headingTenFour",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenFour",
    aria_controls: "collapseTenFour", 
    actice: "",

  }, 
  {
    id: 15,
    question: "What specific web development services does DigiExpo offer?",
    answer: <>DigiExpo website development company provides clients professional web development solutions that fit their needs. Our WordPress development services enable us to assist you in making your idea a WordPress reality that, in return, will depict your business and encourage growth. Our company's Laravel development services are derived from the principles of delivering robust, reliable, and highly secure web applications in the least time, assuring higher ROI and competitive advantage. We also build custom PHP-based enterprise web portals, advanced e-commerce, and business solutions. We create powerful, customer-oriented, complex, and feature-incorporated web solutions. Furthermore, CMS development services enable our clients to have efficient solutions for their content management needs. Our WhatsApp integration services help our clients increase their sales through better customer interaction since WhatsApp is frequently used by business people worldwide.</> ,
    accordion_id: "headingTenFive",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenFive",
    aria_controls: "collapseTenFive", 
    actice: "",

  }, 
  {
    id: 16,
    question: "What eCommerce platforms does DigiExpo specialize in?",
    answer: <>DigiExpo is a premier eCommerce website development company in UAE that works on different platforms: WooCommerce, Mirakl, Magento, BigCommerce, Shopify, and Shopify Plus. We developed both platforms to cater to the diverse needs of businesses, ensuring a smooth and efficient online shopping experience.</> ,
    accordion_id: "headingTenSix",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenSix",
    aria_controls: "collapseTenSix", 
    actice: "",

  }, 
  {
    id: 17,
    question: "How does DigiExpo ensure a seamless customer experience on my eCommerce website?",
    answer: <>DigiExpo aims to develop customer-specific and generic solutions that enhance the client's experience on any device. Its unique design, easy-to-use frontends, both safe methods of payment, and powerful administration provide a solid foundation for the smooth work of your eCommerce site.</> ,
    accordion_id: "headingTenSeven",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenSeven",
    aria_controls: "collapseTenSeven", 
    actice: "",

  }, 
  {
    id: 18,
    question: "What is the process for developing an eCommerce website at DigiExpo?",
    answer: <>While developing any feature or tool, our developers go through the phases of detailed requirement assessment, design and build, third-party tool integration and configuration, and exhaustive testing before going live. Therefore, we guarantee a comprehensive approach to enhancing eCommerce websites, ensuring optimal performance and safe user engagement.</> ,
    accordion_id: "headingTenEight",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenEight",
    aria_controls: "collapseTenEight", 
    actice: "",

  }, 
  {
    id: 19,
    question: "Can DigiExpo help me create a marketplace using Mirakl?",
    answer: <>Yes, with Mirakl's advanced tool set, DigiExpo can help you establish a profitable online marketplace. We assist in reaching out to a vast pool of sellers and equally ensure rapid order processing, as well as offering a first-rate marketplace service that mostly appeals to the potential target market.</> ,
    accordion_id: "headingTenNine",
    collapsed: "collapsed",
    data_bs_target: "#collapseTenNine",
    aria_controls: "collapseTenNine", 
    actice: "",

  }, 
  {
    id: 20,
    question: "What are the benefits of using Shopify Plus with DigiExpo's development services? ",
    answer: <>DigiExpo deals with Shopify Plus and provides services to customize and enhance its functionality, making it suitable for small businesses and high-volume merchants. We guarantee unbeatable efficiency, capacity, and protection, making your business advance wiser with a store that draws and persuades more clients.</> ,
    accordion_id: "headingTwoZero",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoZero",
    aria_controls: "collapseTwoZero", 
    actice: "",

  }, 
  {
    id: 21,
    question: "Why is mobilе app dеvеlopmеnt significant?",
    answer: <>In today's world, whеrе an avеragе pеrson spеnds 90% of his timе on a mobilе phonе using applications, your businеss must havе onе. Our mobilе app dеvеlopеrs build rеsponsivе and functional applications according to your businеss rеquirеmеnts. A seamless usеr еxpеriеncе is created to keep thе customеr engaged.</> ,
    accordion_id: "headingTwoOne",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoOne",
    aria_controls: "collapseTwoOne", 
    actice: "",

  }, 
  {
    id: 22,
    question: "How can I еnsurе thе succеss of my app development projеct with a Pakistan-basеd app dеvеlopmеnt company?",
    answer: <>If you want to еnsurе thе succеss of your app development projеct, it is crucial to еstablish clеar projеct goals, еxpеctations, and budgеts. Working with reliable mobile wеb application dеvеlopmеnt in UAE is highly recommended. Maintain open and transparent communication with thе dеvеlopmеnt team, provide timеly fееdback, and bе willing to collaboratе and makе nеcеssary improvements. Additionally, choosе a company with a provеn track rеcord of completing app development projects and еxpеrtisе in thе industry.</> ,
    accordion_id: "headingTwoTwo",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoTwo",
    aria_controls: "collapseTwoTwo", 
    actice: "",

  }, 
  {
    id: 23,
    question: "How Can I Hirе A Mobilе App Dеvеlopеr?",
    answer: <>In today's digital еra, finding skillеd mobile app developers has bеcomе еffortlеss. Discovеr DigiExpo, a lеading mobilе app dеvеlopmеnt company in Dubai, offering dedicated dеvеlopеrs for hire. Expеriеncе thеir expertise at a budget-friendly price point.</> ,
    accordion_id: "headingTwoThree",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoThree",
    aria_controls: "collapseTwoThree", 
    actice: "",

  }, 
  {
    id: 24,
    question: "Why should you choosе DigiExpo as your top option for application dеvеlopmеnt?",
    answer: <>Our company prides itself on providing exceptional mobile application development sеrvicеs that transform your ideas into reality. Our tеam of skillеd profеssionals is committеd to crafting apps with thе following attributеs:<br/>
    1. An impеccably designed usеr intеrfacе<br/>2. Robust data protеction mеasurеs<br/>3. Sеamlеss responsiveness<br/>4. Quick loading timеs<br/>5. Outstanding usеr support<br/>6. Built-in intеgrations<br/></> ,
    accordion_id: "headingTwoFour",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoFour",
    aria_controls: "collapseTwoFour", 
    actice: "",

  }, 
  {
    id: 25,
    question: "What is the process for converting my PSD design to responsive HTML?",
    answer: <>At DigiExpo, the best UI/UX Design Agency in Dubai, our approach entails converting your design created in PSD format to HTML for optimum or responsive design. This is done in a meticulous and precise style using genuine code that guarantees the right appearance and functionality regardless of the device. Our team’s goal is to have the best SEO, efficient loading times, great looks, and usability on the site. </> ,
    accordion_id: "headingTwoFive",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoFive",
    aria_controls: "collapseTwoFive", 
    actice: "",

  }, 
  {
    id: 26,
    question: "How does DigiExpo ensure a seamless user experience across all devices? ",
    answer: <>The leading UI/UX Design Agency in UAE, DigiExpo also mostly uses the features of responsive web design, such as the resources that include flexibility of images, media queries, and fluidity of the grids. We enable the website or app to be properly displayed on desktop computers, portable smartphones, and tablets with equal ease, which makes the application easier to use.</> ,
    accordion_id: "headingTwoSix",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoSix",
    aria_controls: "collapseTwoSix", 
    actice: "",

  }, 
  {
    id: 27,
    question: "What does your UX design service include?",
    answer: <>Our offered UX design services in Dubai implies analyzing the target audience preferences and usage scenarios. We produce interactive designs that enable users to interact with the website, making it easier and more satisfying. From the concept stage to the designing stage, which involves wireframing, prototyping, and design, we produce interactive designs that enable the user to interact with the website.</> ,
    accordion_id: "headingTwoSeven",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoSeven",
    aria_controls: "collapseTwoSeven", 
    actice: "",

  }, 
  {
    id: 28,
    question: "How can an expertly crafted landing page improve my business?",
    answer: <>Landing pages are usually optimized for visitors to help them become interested in the terms provided and persuade them to purchase. We also assist in raising the conversion rate and sales for your business with relevant call-to-action buttons and compelling content.</> ,
    accordion_id: "headingTwoEight",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoEight",
    aria_controls: "collapseTwoEight", 
    actice: "",

  }, 
  {
    id: 29,
    question: "What does a UX audit involve and how can it benefit my digital presence?",
    answer: <>A UX audit is a comprehensive review of your website or application that aims to discover strengths and weaknesses related to usability, accessibility, and UX. The audit reports provide recommendations to increase satisfaction, decrease resistance, and improve engagement and conversions.</> ,
    accordion_id: "headingTwoNine",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwoNine",
    aria_controls: "collapseTwoNine", 
    actice: "",

  }, 
  {
    id: 30,
    question: "What types of emerging technologies does DigiExpo specialize in?",
    answer: <>DigiExpo, the best Emerging Tech Development in Dubai focuses on a wide variety of exalting technologies, such as Artificial Intelligence, Augmented Reality, Metaverse creation, 3D projection, IoT creation, and Gesture-Based services.</> ,
    accordion_id: "headingThreeZero",
    collapsed: "collapsed",
    data_bs_target: "#collapseThreeZero",
    aria_controls: "collapseThreeZero", 
    actice: "",

  }, 
  {
    id: 31,
    question: "How does DigiExpo ensure the quality of its technological solutions?",
    answer: <>DigiExpo, leading Emerging Technologies Development Company in UAE methodology consists of efficiently planning, designing, researching, testing, and evaluating all proposed solutions to increase their quality, security, and reliability.</> ,
    accordion_id: "headingThreeOne",
    collapsed: "collapsed",
    data_bs_target: "#collapseThreeOne",
    aria_controls: "collapseThreeOne", 
    actice: "",

  }, 
  {
    id: 32,
    question: "Can DigiExpo help with integrating AI into my business operations?",
    answer: <>Yes, DigiExpo is proficient with AI development, and the firm can support you in incorporating AI solutions into your organizational procedures to improve working progress, projections, and digital transformation.</> ,
    accordion_id: "headingThreeTwo",
    collapsed: "collapsed",
    data_bs_target: "#collapseThreeTwo",
    aria_controls: "collapseThreeTwo", 
    actice: "",

  }, 
  {
    id: 33,
    question: "What is the process for developing a custom Metaverse project with DigiExpo?",
    answer: <>DigiExpo's Metaverse development process involves close collaboration with clients to understand their vision, followed by strategic planning, innovative prototyping, and meticulous development to create a metaverse that bridges the gap between imagination and reality.</> ,
    accordion_id: "headingThreeThree",
    collapsed: "collapsed",
    data_bs_target: "#collapseThreeThree",
    aria_controls: "collapseThreeThree", 
    actice: "",

  }, 
  {
    id: 34,
    question: "Does DigiExpo offer ongoing support after the launch of a project?",
    answer: <>Yes, DigiExpo, leading Emerging tech company Dubai, UAE, provides ongoing monitoring, performance evaluation, and support after the launch of a project to ensure its continued success and to make any necessary adjustments or updates.</> ,
    accordion_id: "headingThreeFour",
    collapsed: "collapsed",
    data_bs_target: "#collapseThreeFour",
    aria_controls: "collapseThreeFour", 
    actice: "",

  }, 
];
export default answer_question_data;
